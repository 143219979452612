<!-- 题库管理首页 -->
<template>
    <div>
        <div class="main" style="display: flex;">
            <!-- <div style="width: 15%;background: #fff;padding: 10px;margin-right:20px;border-radius: 8px;">
          <el-select v-model="brandId" placeholder="品牌" class="cinput ac" @change='list'>
            <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <div style="display: flex;flex-direction: column;justify-content: center;text-align: center;">
            <div v-for="(item, index) in brandList" :key="item.id" style="margin: 10px;font-size: 14px;"
              :class="index == 0 ? 'changeSel' : 'defaultcolor'">{{  item.name  }}</div>
          </div>
            </div> -->
            <div style="width: 100%;">
                <div style="font-size: 26px; font-weight: bold;display:flex">
                    <div style="cursor: pointer;padding: 10px 0;">人员管理</div>
                </div>
                <div style="display: flex;justify-content: space-between;align-items: center;">
                    <div
                        style="display: flex;border-radius: 8px 8px 0 0;background: #C6E7E2;line-height: 34px;font-size: 14px;">
                        <div v-for="(item, index) in statusList" :key="index" style="width: 112px;text-align: center;"
                            :class="ind == index ? 'currentTitle' : 'titlelayout'" @click="titleChange(index)">{{ item
                            }}
                        </div>
                    </div>
                    <!-- <el-button type="primary" style="height: 34px; line-height: 10px;background: #00A78E;" class="cinput"
                  @click="addcontent2">+添加课程包</el-button> -->
                    <el-button type="primary" style="height: 34px; line-height: 10px;background: #00A78E;" class="cinput"
                        @click="addUser">+新增{{ ind == 0 ? '学员' : ind == 1 ? '讲师' : '代理商' }}</el-button>
                    <!-- <el-button type="primary" style="height: 34px; line-height: 10px;background: #00A78E;"
                        class="cinput" @click="dialogVisible2 = true">+新增讲师</el-button>
                    <el-button type="primary" style="height: 34px; line-height: 10px;background: #00A78E;"
                            class="cinput" @click="dialogVisible3 = true">+新增代理商</el-button> -->
                    <!-- <el-button type="primary" style="height: 34px; line-height: 10px;background: #00A78E;" class="cinput"
                  @click="dialogVisibleContent = true">内容管理</el-button> -->
                </div>
                <div
                    style="height: 60px;background: #fff;display: flex;justify-content: space-between;padding: 0 20px;align-items: center;">
                    <el-input placeholder="请输入名称或手机号" prefix-icon="el-icon-search" v-model="searchStr" style="width: 232px;"
                        v-if="ind != 1">
                    </el-input>
                    <div style="display: flex;align-items: center;" v-if="ind == 0">
                        <div style="color: #3D3D3D;font-size: 14px;margin-left: 10px;">会员状态:</div>
                        <el-select v-model="vipStr" placeholder="会员" clearable class="cinput ac" style="width: 104px;">
                            <el-option v-for="item in vipList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="display: flex;align-items: center;" v-if="ind != 1">
                        <div style="color: #3D3D3D;font-size: 14px;margin-left: 10px;">品牌归属:</div>
                        <el-select v-model="brandIdStr" placeholder="品牌" clearable class="cinput ac" @change='selectBrand'
                            style="width: 104px;">
                            <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="display: flex;align-items: center;" v-if="ind == 0">
                        <div style="color: #3D3D3D;font-size: 14px;margin-left: 10px;">代理商:</div>
                        <el-select v-model="agentUserIdStr" placeholder="代理商" clearable class="cinput ac"
                            style="width: 104px;">
                            <el-option v-for="item in tableData3" :key="item.userId" :label="item.name"
                                :value="item.userId">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="display: flex;align-items: center;" v-if="ind == 0">
                        <div style="color: #3D3D3D;font-size: 14px;margin-left: 10px;">入会时间:</div>
                        <el-date-picker style="width: 240px;" v-model="dateList" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </div>
                </div>
                <div style="display: flex;padding: 10px 20px;justify-content: flex-end;background: #fff;">
                    <!-- <el-button icon="el-icon-download" style="color: #00A78E;border-color: #00A78E;"
                        @click="exportExcel2">导出用户提问</el-button> -->
                    <el-button icon="el-icon-download" style="color: #00A78E;border-color: #00A78E;" v-if="ind == 0"
                        @click="exportExcel">导出</el-button>
                    <el-button icon="el-icon-refresh-right" style="color: #00A78E;border-color: #00A78E;"
                        @click="reset">重置</el-button>
                    <el-button icon="el-icon-search" style="color: #00A78E;border-color: #00A78E;" @click="search">搜索
                    </el-button>
                </div>

                <el-table :header-cell-style="{ background: '#F7F7F7' }" :data="tableData"
                    style="width: 100%; min-height: 600px;padding: 20px;" @selection-change="handleSelectionChange"
                    v-if="ind == 0">
                    <el-table-column prop="name" align="center" label="学员名称"></el-table-column>
                    <el-table-column prop="vip" align="center" label="会员状态">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.vip == 0 ? '非会员' : scope.row.vip == 1 ? '付费会员' : scope.row.vip == 2 ? '内部会员' :
                                    scope.row.vip == 3 ? '外部购买会员' : scope.row.vip == -1 ? '注销用户' : '未定义' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="brandName" align="center" label="品牌归属"></el-table-column>
                    <el-table-column prop="agentName" align="center" label="代理商归属"></el-table-column>
                    <el-table-column prop="vipTime" align="center" label="入会时间"></el-table-column>
                    <el-table-column prop="teacherName" align="center" label="操作" width="300">
                        <template slot-scope="scope">
                            <div style="display: flex; justify-content: space-around">
                                <el-link type="primary" :underline="false" @click="vipConfig(scope.row)">会员设置
                                </el-link>
                                <el-link type="primary" :underline="false" @click="detailsTrainee(scope.row)">查看详情
                                </el-link>

                                <!-- <el-link type="primary" :underline="false" @click="lookcontent3(scope.row)"
                    v-if="!scope.row.teacherName">
                    添加子集</el-link>
                  <el-link type="primary" :underline="false" @click="lookcontent4(scope.row)"
                    v-if="!scope.row.teacherName">
                        查看子集</el-link> -->
                                <!-- <el-link type="primary" :underline="false" @click="addtext(scope.row)"
                                    v-if="scope.row.teacherName">添加内容
                                </el-link>
                                <el-link type="primary" :underline="false" @click="addtext2(scope.row)"
                                    v-if="scope.row.teacherName">
                                    课程内容
                                </el-link>
                                <el-link type="primary" :underline="false" @click="addtext4(scope.row)"
                                    v-if="scope.row.teacherName">
                                    试题管理
                                    </el-link> -->

                                <!-- <el-dropdown>
                    <img src="../../images/more.png" alt="" style="width: 24px;height: 24px;">
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>详情</el-dropdown-item>
                      <el-dropdown-item>编辑</el-dropdown-item>
                      <el-dropdown-item>删除</el-dropdown-item>
                    </el-dropdown-menu>
                      </el-dropdown> -->
                                <!-- <el-link type="primary" :underline="false" @click="dele(scope.row)">删除</el-link>
                  <el-link type="primary" :underline="false" v-if="radio == '2'" @click="lookvideo(scope.row)">查看视频
                  </el-link>
                  <el-link type="primary" :underline="false" v-if="radio == '3'" @click="lookcontent(scope.row)">查看富文本
                      </el-link> -->

                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-table :header-cell-style="{ background: '#F7F7F7' }" id="out-table" :data="tableDatas"
                    style="width: 100%; min-height: 600px;padding: 20px;display: none;"
                    @selection-change="handleSelectionChange" v-if="ind == 0">
                    <el-table-column prop="name" align="center" label="学员名称"></el-table-column>
                    <el-table-column prop="phone" align="center" label="手机号码"></el-table-column>
                    <el-table-column prop="vip" align="center" label="会员状态">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.vip == 0 ? '非会员' : scope.row.vip == 1 ? '付费会员' : scope.row.vip == 2 ? '内部会员' :
                                    scope.row.vip == 3 ? '外部购买会员' : scope.row.vip == 0 ? '注销用户' : '未定义' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="brandName" align="center" label="品牌归属"></el-table-column>
                    <el-table-column prop="agentName" align="center" label="代理商归属"></el-table-column>
                    <el-table-column prop="vipTime" align="center" label="入会时间"></el-table-column>
                </el-table>
                <el-table :header-cell-style="{ background: '#F7F7F7' }" id="out-table2" :data="tableDatas2"
                    style="width: 100%; min-height: 600px;padding: 20px;display: none;"
                    >
                    <el-table-column prop="content" align="center" label="用户问题"></el-table-column>
                </el-table>
                <el-table :header-cell-style="{ background: '#F7F7F7' }" :data="tableData2"
                    style="width: 100%; min-height: 600px;padding: 20px;" @selection-change="handleSelectionChange"
                    v-if="ind == 1">
                    <el-table-column prop="name" align="center" label="讲师图片">
                        <template slot-scope="scope">
                            <div>
                                <img :src="scope.row.picUri" alt="" style="width: 104px;height: 104px;">
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" align="center" label="讲师名称"></el-table-column>
                    <el-table-column prop="phone" align="center" label="讲师电话"></el-table-column>
                    <el-table-column prop="name" align="center" label="性别">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.sex == 0 ? '女' : '男' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="birthday" align="center" label="讲师生日"></el-table-column>
                    <el-table-column prop="teacherName" align="center" label="操作" width="300">
                        <template slot-scope="scope">
                            <div style="display: flex; justify-content: space-around">
                                <el-link type="primary" :underline="false" @click="detailsTeacher(scope.row)">详情
                                </el-link>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-table :header-cell-style="{ background: '#F7F7F7' }" :data="tableData3"
                    style="width: 100%; min-height: 600px;padding: 20px;" @selection-change="handleSelectionChange"
                    v-if="ind == 2">
                    <!-- <el-table-column prop="name" align="center" label="讲师图片">
                        <template slot-scope="scope">
                            <div>
                                <img :src="scope.row.picUri" alt="" style="width: 104px;height: 104px;">
                            </div>
                        </template>
                        </el-table-column> -->
                    <el-table-column prop="name" align="center" label="代理商名称"></el-table-column>
                    <el-table-column prop="phone" align="center" label="代理商电话"></el-table-column>
                    <el-table-column prop="name" align="center" label="品牌归属">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.brandId == 1 ? '嘉顿肯妮' : scope.row.brandId == 2 ? '瑞妮朵拉' : scope.row.brandId ==
                                    3 ? '素颜+' : scope.row.brandId == 10 ? '外部学员' : '测试数据' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="province" align="center" label="地区">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.provinceName }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="teacherName" align="center" label="操作" width="300">
                        <template slot-scope="scope">
                            <div style="display: flex; justify-content: space-around">
                                <el-link type="primary" :underline="false" @click="detailsUserData(scope.row)">用户数据
                                </el-link>
                                <el-link type="primary" :underline="false" @click="detailsAgetn(scope.row)">详情
                                </el-link>

                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div v-if="ind == 0" style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding:20px;
                background: #fff;
              ">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="pageIndex" :page-sizes="[10, 15, 20]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                </div>
            </div>
        </div>

        <el-dialog :title="isAdd ? '新增学员' : '学员详情'" :visible.sync="dialogVisible" width="60%" class="dialog1">
            <div style="display: flex;justify-content: space-between;height: 100%;">
                <div style="width: 50%;border-right: 1px solid #E5E5E5;">
                    <div style="display: flex;align-items: center;font-size: 14px;width: 100px;justify-content: flex-end;">
                        <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;">
                        </div>
                        <div>基础设置</div>
                    </div>
                    <div>
                        <div style="display: flex;align-items: center;">
                            <div style="width: 120px;text-align: right;">学员名称：</div>
                            <el-input v-model="name" placeholder="请输入学员包名称" style="width: 264px;"></el-input>
                        </div>
                        <div style="display: flex;align-items: center;">
                            <div style="width: 120px;text-align: right;">学员手机：</div>
                            <el-input v-model="phone" placeholder="请输入学员手机" style="width: 264px;"></el-input>
                        </div>
                        <div style="display: flex;align-items: center;margin: 16px 0;">
                            <div style="width: 120px;text-align: right;">学员品牌：</div>
                            <el-select v-model="brandId" placeholder="品牌" clearable class="cinput ac" @change='list4'
                                style="width: 160px;">
                                <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div style="display: flex;align-items: center;margin: 16px 0;">
                            <div style="width: 120px;text-align: right;">代理商：</div>
                            <el-select v-model="agentUserId" placeholder="代理商" clearable filterable class="cinput ac"
                                style="width: 160px;">
                                <el-option v-for="item in tableData3" :key="item.userId" :label="item.name"
                                    :value="item.userId">
                                </el-option>
                            </el-select>
                        </div>
                        <!-- <div style="display: flex;align-items: center;margin: 16px 0;" v-if="radio == 2">
                            <div style="width: 120px;text-align: right;">课程关联：</div>
                            <el-select v-model="brandId" placeholder="品牌" clearable class="cinput ac" @change='list'
                                style="width: 160px;">
                                <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                            <el-select v-model="brandId" placeholder="品牌" clearable class="cinput ac" @change='list'
                                style="width: 160px;">
                                <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div style="display: flex;align-items: center;margin: 16px 0;" v-if="radio == 3">
                            <div style="width: 120px;text-align: right;">关联商品：</div>
                            <el-select v-model="brandId" placeholder="品牌" clearable class="cinput ac" @change='list'
                                style="width: 160px;">
                                <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                            </div> -->


                        <div
                            style="display: flex;align-items: center;font-size: 14px;margin-top: 50px;width: 115px;justify-content: flex-end;">
                            <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;">
                            </div>
                            <div>会员</div>

                        </div>
                        <div style="display: flex;align-items: center;">
                            <div style="width: 50px;text-align: right;"></div>
                            <div style="margin: 20px 0;">
                                <el-radio-group v-model="vip" text-color="#00A78E">
                                    <el-radio :label="item.value" v-for="item in vipList" :key="item" disabled>{{ item.label
                                    }}</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div
                            style="display: flex;align-items: center;font-size: 14px;margin-top: 50px;width: 170px;justify-content: flex-end;">
                            <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;">
                            </div>
                            <div>其他数据（选填）</div>

                        </div>
                        <div style="display: flex;align-items: center;">
                            <div style="width: 150px;text-align: right;">性别：</div>
                            <div style="margin: 20px 0;">
                                <el-radio-group v-model="sex" text-color="#00A78E">
                                    <el-radio :label="0">女</el-radio>
                                    <el-radio :label="1">男</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div style="display: flex;align-items: center;">
                            <div style="width: 150px;text-align: right;">生日：</div>
                            <el-date-picker v-model="birthday" type="date" placeholder="选择日期" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                        <div style="display: flex;align-items: center;margin: 20px 0;">
                            <div style="width: 150px;text-align: right;">地区：</div>
                            <el-cascader placeholder="地区选择" :options="citys" clearable v-model='code'>
                            </el-cascader>
                        </div>
                        <div style="display: flex;align-items: center;margin: 20px 0;">
                            <div style="width: 150px;text-align: right;">门店：</div>
                            <el-input v-model="storeName" placeholder="请输入门店名称" style="width: 220px;"></el-input>
                        </div>
                    </div>
                </div>
                <div style="width: 50%;">
                    <div style="display: flex;align-items: center;font-size: 14px;" v-if="traineeIds">
                        <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;">
                        </div>
                        <div>学习信息</div>
                    </div>
                    <div style="display: flex;align-items: center;margin: 16px 0;" v-if="traineeIds">
                        <div style="width: 120px;text-align: right;">等级：</div>
                        <div>{{ levelId }}</div>
                    </div>
                    <div style="display: flex;align-items: center;margin: 16px 0;" v-if="traineeIds">
                        <div style="width: 120px;text-align: right;">总学习时长：</div>
                        <div>{{ studyTimeMinutes | stime }}</div>
                    </div>
                    <div style="display: flex;align-items: center;font-size: 14px;" v-if="traineeIds">

                        <div style="display:flex;align-items:center;flex-direction: column;" @click="levelStudyList">
                            <div :class="statusInd == 0 ? 'selTitle' : 'noselTitle'">各等级学习时长</div>
                            <div style="width: 33px;height: 4px;border-radius: 50px;margin-right: 4px;"
                                :class="statusInd == 0 ? 'b00' : 'bff'">
                            </div>
                        </div>
                        <div style="display:flex;align-items:center;margin-left:10px;flex-direction: column;"
                            @click="courseStudyList">
                            <div :class="statusInd == 1 ? 'selTitle' : 'noselTitle'">各课程学习时长</div>
                            <div style="width: 33px;height: 4px;border-radius: 50px;margin-right: 4px;"
                                :class="statusInd == 1 ? 'b00' : 'bff'">
                            </div>
                        </div>
                    </div>
                    <el-table :data="tableDataStatus" style="width: 100%" :header-cell-style="{ background: '#F1F1F1' }"
                        v-if="traineeIds">
                        <el-table-column prop="levelName" label="等级名称" v-if="statusInd == 0" align="center">
                        </el-table-column>
                        <el-table-column prop="studyMinutes" label="学习时长" v-if="statusInd == 0" align="center" sortable>
                            <template slot-scope="scope">
                                <div style="color: #1890FF;">
                                    {{ scope.row.studyMinutes | stime }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="courseName" label="课程名称" v-if="statusInd == 1" align="center">
                        </el-table-column>
                        <el-table-column prop="studyMinutes" label="学习时长" v-if="statusInd == 1" align="center" sortable>
                            <template slot-scope="scope">
                                <div style="color:#1890FF">
                                    {{ scope.row.studyMinutes | stime }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="passState" label="自测" v-if="statusInd == 1" align="center">
                            <template slot-scope="scope">
                                <div :style="{ color: (scope.row.passState == 1 ? '#52C41A' : '#FF4D4F') }">
                                    {{ scope.row.passState == 1 ? '通过' : '未通过' }}</div>
                            </template>
                        </el-table-column>

                    </el-table>
                    <div style="position: absolute;bottom: 0;display: flex;width: 48%;justify-content: flex-end;">
                        <!-- <el-button @click="refreshToken" v-if="traineeIds">token过期</el-button> -->
                        <el-button @click="dialogVisible = false">取消</el-button>
                        <el-button style="background: #00A78E;color: #fff;" @click="confirmAddTrainee">保存</el-button>
                    </div>
                </div>

            </div>
        </el-dialog>


        <el-dialog :title="isAdd ? '新增老师' : '老师详情'" :visible.sync="dialogVisible2" width="60%" class="dialog1">
            <div style="display: flex;justify-content: space-between;">
                <div style="width:50%">
                    <div
                        style="display: flex;align-items: center;font-size: 14px;width: 100px;justify-content: flex-end;margin-bottom: 10px;">
                        <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;">
                        </div>
                        <div>基础设置</div>
                    </div>
                    <div style="display: flex;align-items: center;">
                        <div style="width: 120px;text-align: right;">讲师名称：</div>
                        <el-input v-model="name" placeholder="请输入讲师名称" style="width: 264px;"></el-input>
                    </div>
                    <div style="display: flex;align-items: center;margin: 20px 0;">
                        <div style="width: 120px;text-align: right;">讲师手机：</div>
                        <el-input v-model="phone" placeholder="请输入讲师手机号码" style="width: 264px;"></el-input>
                    </div>
                    <div style="display: flex;">
                        <div style="width: 120px;text-align: right;">简介：</div>
                        <el-input type="textarea" :rows="5" placeholder="请输入简介" v-model="des" style="width: 264px;"
                            maxlength="108" show-word-limit>
                        </el-input>
                    </div>
                    <div
                        style="display: flex;align-items: center;font-size: 14px;width: 100px;justify-content: flex-end;margin:20px 0 10px 0;">
                        <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;">
                        </div>
                        <div>其他设置</div>
                    </div>
                    <div style="display: flex;align-items: center;margin: 16px 0;">
                        <div style="width: 120px;text-align: right;">性别：</div>
                        <div>
                            <el-radio-group v-model="sex" text-color="#00A78E">
                                <el-radio :label="0">女</el-radio>
                                <el-radio :label="1">男</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div style="display: flex;align-items: center;margin: 16px 0;">
                        <div style="width: 120px;text-align: right;">生日：</div>
                        <div>
                            <el-date-picker v-model="birthday" type="date" placeholder="选择日期" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                    </div>
                    <div style="display: flex;align-items: center;margin: 16px 0;">
                        <div style="width: 120px;text-align: right;">讲师照片：</div>
                        <img :src="picUri" alt="" style="width: 104px;height: 104px;" v-if="picUri">
                        <input type="file" @change="uploadimg2" id='file2' accept='image/*' ref='fileimg' style="color:#fff"
                            v-if="!picUri" />
                        <el-link type="primary" :underline="false" @click="picUri = ''" v-if="picUri">删除</el-link>
                    </div>

                </div>
                <div style="width:50%;overflow: auto;">
                    <div style="display: flex;align-items: center;font-size: 14px;margin-bottom: 10px;">
                        <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;"
                            >
                        </div>
                        <div>
                            <div>讲师课程</div>
                        </div>
                    </div>
                    <div style="display: flex;align-items: center;margin: 16px 0;" v-if="userIds">
                                <div>所属品牌：</div>
                                <el-select v-model="courseBrandId" placeholder="品牌"  >
                                    <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                    <div style="padding: 10px;" v-if="userIds">
                        <div style="display:flex;flex-direction:column;margin:20px 0;box-shadow: 0px 4px 10px 0px rgba(62, 68, 77, 0.2);padding:20px"
                            v-for="(item, index) in tableDataTeacher" :key="index">
                            <div style="display: flex;border-bottom:1px solid #E5E5E5;padding-bottom: 20px;"
                                @click="changeCourse(item.record.checked, index, item.record.id)">
                                <img :src="item.record.picUri" alt="" style="width: 88px;height: 88px;">
                                <div
                                    style="display: flex;flex-direction: column;width: 100%;justify-content: space-between;margin-left: 20px;">
                                    <div style="display: flex;justify-content: space-between;">
                                        <div>{{ item.record.name }}</div>
                                        <div style="display: flex;align-items: center;">
                                            <img v-if="item.record.configValue == 2 || item.record.configValue == 3"
                                                src="@/images/payVip.png" alt="" style="width: 24px;height: 24px;">
                                            <img v-if="item.record.configValue == 4 || item.record.configValue == 5"
                                                src="@/images/payMoney.png" alt="" style="width: 24px;height: 24px;">
                                            <div
                                                :class="item.record.configValue == 2 || item.record.configValue == 3 ? 'cstatus1' : item.record.configValue == 4 || item.record.configValue == 5 ? 'cstatus2' : 'cstatus3'">
                                                {{ item.record.configValue == 2 || item.record.configValue == 3 ? "会员课程" :
                                                    item.record.configValue == 4 || item.record.configValue == 5 ? "付费课程" :
                                                        "免费课程"
                                                }}</div>
                                        </div>
                                    </div>
                                    <div>
                                        {{ brandId == 1 ? '嘉顿肯妮' : brandId == 2 ? '瑞妮朵拉' : brandId == 3 ? '素颜+' : brandId ==
                                            10 ? '外部学员' : '测试数据' }}
                                    </div>
                                    <div style="display: flex;justify-content: space-between;">
                                        <div>{{ item.record.categoryName }}</div>
                                        <div style="display: flex;align-items: center;">
                                            <img src="../../images/dz.png" style="width: 24px;height: 24px;">
                                            <div>{{ item.record.likeCount }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="item.record.checked">
                                <div style="display: flex;justify-content: space-between;margin: 20px 0;"
                                    v-for="item in courseContentList" :key="item.id">
                                    <div>{{ item.name }}</div>
                                    <div style="display: flex;">
                                        <!-- <el-link type="primary" :underline="true">编辑</el-link> -->
                                        <el-link type="primary" :underline="true" style="margin-left: 10px"
                                            @click="deleteContent(item.id, item.courseId)">删除</el-link>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                    <span slot="footer" class="dialog-footer" style="position: fixed;bottom: 20px;right: 20px;">
                        <el-button @click="dialogVisible2 = false">取 消</el-button>
                        <el-button type="primary" style="background: #00A78E;color: #fff;" @click="confirmAddTeacher">确
                            定</el-button>
                    </span>
                </div>
            </div>


        </el-dialog>

        <el-dialog :title="isAdd ? '新增代理商' : '代理商详情'" :visible.sync="dialogVisible3" width="60%" class="dialog1">
            <div style="display: flex;justify-content: space-between;height: 100%;">
                <div style="width: 50%;">
                    <div style="display: flex;align-items: center;font-size: 14px;width: 100px;justify-content: flex-end;">
                        <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;">
                        </div>
                        <div>基础设置</div>
                    </div>
                    <div>
                        <div style="display: flex;align-items: center;margin: 20px 0;">
                            <div style="width: 120px;text-align: right;">代理商名称：</div>
                            <el-input v-model="name" placeholder="请输入代理商名称" style="width: 264px;"></el-input>
                        </div>
                        <div style="display: flex;align-items: center;">
                            <div style="width: 120px;text-align: right;">代理商手机：</div>
                            <el-input v-model="phone" placeholder="请输入代理商手机" style="width: 264px;"></el-input>
                        </div>

                        <div style="display: flex;align-items: center;margin: 16px 0;">
                            <div style="width: 120px;text-align: right;">所属品牌：</div>
                            <el-select v-model="brandId" placeholder="品牌" clearable :disabled="agentIds ? true : false">
                                <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div style="display: flex;align-items: center;margin: 20px 0;">
                            <div style="width: 120px;text-align: right;">地区：</div>
                            <!-- <el-cascader placeholder="地区选择" :options="citys" clearable v-model='code'>
                                </el-cascader> -->
                            <el-select v-model="provinceId" placeholder="请选择" clearable>
                                <el-option v-for="item in province" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div style="display: flex;align-items: center;margin: 16px 0;">
                            <div style="width: 120px;text-align: right;">品牌：</div>
                            <img :src="wxacodeUri" alt="" style="width: 200px;height: 200px;" id="imageWrapper" />
                        </div>
                        <div style="display: flex;align-items: center;margin: 20px 0;">
                            <div style="width: 120px;text-align: right;">学员必填项：</div>
                            <div style="display: flex;align-items: center;margin: 0 20px;">
                                <div>门店：</div> <el-switch v-model="userstoreName" active-color="#13ce66">
                                </el-switch>
                            </div>
                            <!-- <div style="display: flex;align-items: center;">
                               <div>地区：</div> <el-switch v-model="userarea" active-color="#13ce66" >
                            </el-switch>
                            </div> -->
                        </div>
                        <div style="display: flex;align-items: center;margin: 16px 0;" v-if="userIds">
                            <div style="width: 130px;text-align: center;color: rgb(0, 167, 142);" @click="lookAgent">
                                点击查看购买vip数量</div>
                            <el-select v-model="month" placeholder="请选择">
                                <el-option v-for="item in monthList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>

                        </div>
                        <div style="display: flex;align-items: center;margin: 16px 0;justify-content: center;"
                            v-if="userIds">
                            <div style="width: 120px;text-align: center;margin-right: 20px;">当月数量：{{ vipData.month }}</div>
                            <div style="width: 120px;text-align: center;">总数量：{{ vipData.all }}</div>
                        </div>
                        <div style="width: 130px;text-align: center;color: rgb(0, 167, 142);" @click="lookAgentData"
                            v-if="userIds">点击查看账号密码</div>
                        <div>
                            <div style="display: flex;align-items: center;" v-if="userIds">
                                <div style="width: 120px;text-align: right;margin: 10px 0;">账号：</div>
                                <div>{{ userData.account }}</div>
                            </div>
                            <div style="display: flex;align-items: center;" v-if="userIds">
                                <div style="width: 120px;text-align: right;">密码：</div>
                                <div>{{ userData.pwdSix }}</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div style="width:50%;position: relative;display: flex;justify-content: flex-end;">
                    <div style="position: absolute;bottom: 0;display: flex;width: 48%;justify-content: flex-end;">
                        <el-button @click="dialogVisible3 = false">取消</el-button>
                        <el-button style="background: #00A78E;color: #fff;" @click="confirmAddAgent">保存</el-button>
                    </div>
                </div>

            </div>
        </el-dialog>

        <el-dialog title="内容管理" :visible.sync="dialogVisibleContent" width="60%" class="dialog1">
            <div style="color: #ACB6B5">当前位置：嘉顿肯妮-专业课程</div>
            <div style="display: flex;height: 90%;margin: 20px 0;">
                <div
                    style="padding: 8px 16px;box-shadow: 0px 4px 10px 0px rgba(62, 68, 77, 0.2);height: 100%;width: 144px;border-radius: 10px;">
                    <div style="color: #ACB6B5;">课程包目录</div>
                    <div style="margin: 16px 0;position: relative;">
                        <div
                            style="position: absolute;width: 5px;height: 24px;border-radius: 0px 27px 30px 0px;background: #00A78E;left: -16px;top: -2px;">
                        </div>
                        <div class="selectContent">美容手法</div>
                    </div>
                </div>
                <div style="width: 80%;margin-left: 20px;">
                    <div style="display: flex;justify-content: flex-end;">
                        <el-button style="color: #fff;background: #00A78E;" @click="dialogVisibleContentAdd = true">
                            +添加内容
                        </el-button>
                    </div>
                    <el-table :data="tableData2"
                        style="width: 95%;display: flex;flex-direction: column;align-items: flex-end;margin-top: 20px;"
                        :header-cell-style="{ background: '#F7F7F7' }">
                        <el-table-column prop="date" label="课程包名称">
                        </el-table-column>
                        <el-table-column prop="name" label="权重">
                        </el-table-column>
                        <el-table-column prop="address" label="操作">
                            <template slot-scope="scope">
                                <div>
                                    <el-link type="primary" :underline="false">编辑</el-link>
                                    <el-link type="primary" :underline="false" style="margin-left: 20px;">删除</el-link>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </el-dialog>



        <el-dialog title="添加推荐" :visible.sync="dialogVisibleContentAdd" width="30%" :before-close="handleClose">
            <div style="overflow: auto;">
                <!-- <div
            style="display: flex;align-items: center;font-size: 14px;width: 100px;justify-content: flex-end;margin-bottom: 10px;">
            <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;"></div>
            <div>基础设置</div>
              </div> -->
                <div style="display: flex;align-items: center;">
                    <div style="width: 80px;text-align: right;">推荐标题：</div>
                    <el-input v-model="input" placeholder="请输入推荐标题" style="width: 264px;"></el-input>
                </div>
                <div style="display: flex;align-items: center;margin: 20px 0;">
                    <div style="width: 80px;text-align: right;">课程权重：</div>
                    <el-input v-model="input" placeholder="请输入数字" style="width: 264px;"></el-input>
                </div>
                <div style="display: flex;align-items: center;margin: 16px 0;">
                    <div style="width: 80px;text-align: right;">视频上传：</div>
                    <input type="file" id='file2' accept='video/mp4' ref='fileimg' style="color:#fff"
                        @change="uploadvideo" />
                    <video :src="vidersrc" v-if="vidersrc" class="avatar" controls="controls"
                        style="width: 351px; height: 198px" id="videosrc">
                        您的浏览器不支持视频播放
                    </video>
                </div>
                <div style="display: flex;margin: 20px 0;flex-direction: column;">
                    <div style="width: 80px;text-align: right;">课件设置：</div>
                    <quill-editor style="height:200px;width:100%;" v-model="msg" ref="richAnalysis" :options="options2"
                        @change="quillchange"></quill-editor>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" style="background: #00A78E;color: #fff;">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="会员设置" :visible.sync="dialogVip" width="30%">
            <div style="overflow: auto;">
                <el-radio-group v-model="vip" text-color="#00A78E" style="display: flex;flex-direction: column;">
                    <el-radio :label="item.value" v-for="item in vipList" :key="item" style="margin:10pr 0">{{ item.label
                    }}</el-radio>
                </el-radio-group>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVip = false">取 消</el-button>
                <el-button type="primary" style="background: #00A78E;color: #fff;" @click="saveVip">保 存</el-button>
            </span>
        </el-dialog>

        <el-dialog title="用户数据" :visible.sync="dialogVisibleUserData" width="90%" top="5vh" class="usertable">
            <UserTabel :componentUseAgentId="componentUseAgentId" ref="UserTabel"></UserTabel>
        </el-dialog>
    </div>
</template>

<script>
var COS = require('cos-js-sdk-v5');
var cos = new COS({
    getAuthorization: function (options, callback) {
        getCosCredit().then(result => {
            var data = result.data.data;
            var credentials = data && data.credentials;
            if (!data || !credentials) return console.error('credentials invalid');
            sessionStorage.setItem('bucket', data.bucket)
            callback({
                TmpSecretId: credentials.tmpSecretId,
                TmpSecretKey: credentials.tmpSecretKey,
                XCosSecurityToken: credentials.sessionToken,
                // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
                ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
            });
        })
    }
});
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['link', 'image', 'video'],
    ['clean']                                         // remove formatting button
]
import UserTabel from '../../conponents/UserTabel.vue'
import FileSaver from "file-saver";
import * as XLSX from 'xlsx'
import { cityList, provinceList } from '../../utils/cityCode'
import { getAiList,agentUpdate, getUserData, getAgentVip, tokenChange, contentDelet, courseList, getLevelList, contentList, getcourselList, updateVipStatus, courseInfoList, levelList, getCosCredit, coursedelete, agentDes, currentCourseList, courseDeleteAll, teacherAdd, teacherList, agentAdd, agentList, traineeAdd, traineeList, teacherUpdate, traineeLook, traineeUpdate } from '../../apis/index'
export default {
    data() {
        return {
            tableDatas2:[],
            courseBrandId:1,
            userstoreName: false,
            // userarea:true,
            dialogVip: false,
            vipList: [
                {
                    label: '注销用户',
                    value: -1
                },
                {
                    label: '默认用户',
                    value: 0
                },
                {
                    label: '付费用户',
                    value: 1
                },
                {
                    label: '内部用户',
                    value: 2
                },
                {
                    label: '外部购买会员',
                    value: 3
                },
            ],
            componentUseAgentId: '',
            dialogVisibleUserData: false,
            monthList: [
                {
                    value: '1',
                    label: '一月'
                },
                {
                    value: '2',
                    label: '二月'
                },
                {
                    value: '3',
                    label: '三月'
                },
                {
                    value: '4',
                    label: '四月'
                },
                {
                    value: '5',
                    label: '五月'
                },
                {
                    value: '6',
                    label: '六月'
                },
                {
                    value: '7',
                    label: '七月'
                },
                {
                    value: '8',
                    label: '八月'
                },
                {
                    value: '9',
                    label: '九月'
                },
                {
                    value: '10',
                    label: '十月'
                },
                {
                    value: '11',
                    label: '十一月'
                },
                {
                    value: '12',
                    label: '十二月'
                },
            ],
            month: '',
            vipData: {},
            userData: {},
            isAdd: true,
            vipStr: '',
            brandIdStr: '',
            agentUserIdStr: '',
            wxacodeUri: '',
            tableDatas: [],//导出表格专用
            courseContentList: [],//课程内容列表
            levelId: '',
            tableDataTeacher: [],//讲师课程包列表
            studyTimeMinutes: 0,//总学习时长
            statusInd: 0,//切换学习时长的
            tableDataStatus: [],
            teacherIds: '',//教师id，编辑专用
            traineeIds: '',//学员id,编辑专用
            agentIds: '',//代理商id,编辑专用
            userIds: '',
            dateList: [],//入会时间
            // vipList: [
            //     {
            //         label: '非会员',
            //         value: 0
            //     },
            //     {
            //         label: '付费会员',
            //         value: 1
            //     },
            //     {
            //         label: '内部会员',
            //         value: 2
            //     }
            // ],//会员列表
            searchStr: '',//搜索关键字
            province: [],//省份列表
            provinceId: '',//省份id
            code: [],
            agentUserId: '',
            vip: '',
            defaultVip: '',
            ind: 0,//0学员，1讲师，2代理商
            birthday: '',//讲师生日
            des: '',//讲师简介
            name: '',//讲师名称
            phone: '',//讲师手机号
            picUri: '',//讲师照片
            sex: '',//讲师性别
            tableData3: [],
            tableData2: [],
            tableData1: [],
            storeName: '',//门店名称
            dialogVisible: false,
            dialogVisible2: false,
            dialogVisible3: false,
            dialogVisibleContent: false,//内容管理
            dialogVisibleContentAdd: false,//添加内容
            statusList: ['学员', '讲师', '代理商'],
            brandId: '',
            brandList: [],
            levelLists: [],
            leveId: 1,
            total: 0,
            pageIndex: 1,
            pageSize: 10,
            options: [
                {
                    value: "选项1",
                    label: "黄金糕",
                },
                {
                    value: "选项2",
                    label: "双皮奶",
                },
            ],
            value: "",
            isshow: false,
            isshow2: true,
            isdisabled: true,
            currentPage: 1,
            radio: 1,
            input: "",
            tableData: [

            ],
            options2: {
                theme: "snow",
                placeholder: "请输入内容",
                modules: {
                    toolbar: {
                        container: toolbarOptions,  // 工具栏
                        handlers: {
                            'image': function (value) {
                                if (value) {
                                    document.getElementById('file3').click()
                                } else {
                                    this.quill.format('image', false);
                                }
                            }
                        }
                    }
                }
                // modules: {
                //   toolbar: [
                //     ["bold", "italic", "underline", "strike"], // 加粗，斜体，下划线，删除线
                //     //['blockquote', 'code-block'],                      //引用，代码块
                //     [{ header: 1 }, { header: 2 }], // 几级标题
                //     [{ list: "ordered" }, { list: "bullet" }], // 有序列表，无序列表
                //     [{ script: "sub" }, { script: "super" }], // 下角标，上角标
                //     [{ indent: "-1" }, { indent: "+1" }], // 缩进
                //     //[{ direction: "rtl" }], // 文字输入方向
                //     [{ size: ["small", false, "large", "huge"] }], // 字体大小
                //     [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                //     [{ color: [] }, { background: [] }], // 颜色选择
                //     [{ font: [] }], // 字体
                //     [{ align: [] }], // 居中
                //     ["clean"],　　　　//清除样式
                //     [("link", "image","video")],//控制能上传的内容
                //   ],
                // },
            },
        };
    },
    components: {
        UserTabel
    },
    filters: {
        stime(value) {
            return parseInt(value / 60) + '小时' + value % 60 + '分'
        }
    },
    watch: {
        courseBrandId(newData, oldData){
            this.getcourseList()
        },
        // vip(newdata, olddata) {//监听会员设置
        //     if (this.userIds && this.traineeIds) {//代表在编辑学员
        //         if (newdata == 0) {
        //             updateVipFalse({ userId: this.userIds })
        //         } else if (newdata == 2) {
        //             updateVipTrue({ userId: this.userIds })
        //         }
        //     }
        // },
        agentUserId(newData, oldData) {//通过选择代理商切换学院品牌
            let tableData3 = this.tableData3
            let datas = tableData3.filter(item => {
                return item.userId == newData
            })
            this.brandId = datas[0].brandId
        },
        input(newdata, olddata) {
            console.log(newdata);
            if (newdata) {
                this.isshow = true;
            } else {
                this.input = ''
                this.list()
                // this.lists()
                this.isshow = false;
            }
        },
        radio(newdata, olddata) {
            this.radio = newdata
            this.pageIndex = 1
            this.list()
            // this.lists()
            // if (newdata == "2") {
            //   this.isshow2 = true;
            // } else {
            //   this.isshow2 = false;
            // }
        },
    },
    created() {
        let codes = cityList()//获取省市区数据
        this.citys = codes
        this.brandList = this.$store.state.brandList
        let id = this.$route.query.id
        // let brandId = this.$route.query.brandId
        this.id = id
        let province = provinceList()//获取省份
        this.province = province
        this.list3()//代理商列表
        this.list()//学员列表
        // this.lists()//导出表格专用列表
        // this.brandId = brandId
        // this.getList()
    },
    //     this.leave().then(res => {
    //       this.list()
    //     })
    //   },
    //   beforeRouteLeave(to, from, next) {
    //   if(to.fullPath=='/videoAdd' || to.fullPath=='/contentAdd'){//添加页，就不缓存
    //     this.$route.meta.keepAlive=false
    //     setTimeout(() => {
    //       next()
    //     }, 100);
    //   }else{//详情页缓存
    //   console.log('我是详情缓存')
    //    this.$route.meta.keepAlive=true
    //     setTimeout(() => {
    //       next()
    //     }, 100);
    //   }
    //   // 导航离开该组件的对应路由时调用
    //   // 可以访问组件实例 `this`
    // },
    methods: {
        vipConfig(row) {//会员设置
            this.dialogVip = true
            this.vip = row.vip
            this.userIds = row.userId
            console.log(row)
        },
        async saveVip() {//保存vip
            const { data } = await updateVipStatus({ userId: this.userIds, vipState: this.vip })
            if (data.code == 200) {
                this.dialogVip = false
                this.list()//代理商列表
                this.$message.success(data.msg)
            } else {
                this.$message.error(data.msg)
            }
        },
        detailsUserData(row) {//查看代理商下面的用户数据
            this.componentUseAgentId = row.userId
            this.dialogVisibleUserData = true
            this.$nextTick(() => {
                this.$refs.UserTabel.getUserList()
            })
        },
        async lookAgent() {//查看代理商购买vip数量
            const params = {
                agentUserId: this.userIds,
                month: this.month
            }
            const { data } = await getAgentVip(params)
            if (data.code == 200) {
                this.vipData = data.data
            } else {
                this.$message.error(data.msg)
            }
            console.log(data.data)
        },
        async lookAgentData() {//查看代理商账号密码
            const params = {
                userId: this.userIds,
            }
            const { data } = await getUserData(params)
            if (data.code == 200) {
                this.userData = data.data
            } else {
                this.$message.error(data.msg)
            }
            console.log(data.data)
        },
        selectBrand() {//品牌归属
            if (this.ind == 0) {
                this.list()
                this.list3()
            } else if (this.ind == 2) {
                this.list3()
            }
        },
        async refreshToken() {//刷新token
            const { data } = await tokenChange({ userId: this.userIds })
            if (data.code == 200) {
                this.$message.success('成功')
            }
        },
        exportExcel() {//导出表格
            /* 从表生成工作簿对象 */
            this.lists().then(res => {
                let wb = XLSX.utils.table_to_book(document.querySelector('#out-table'))
                /* 获取二进制字符串作为输出 */
                var wbout = XLSX.write(wb, {
                    bookType: 'xlsx',
                    bookSST: true,
                    type: 'array'
                })
                try {
                    FileSaver.saveAs(
                        //Blob 对象表示一个不可变、原始数据的类文件对象。
                        //Blob 表示的不一定是JavaScript原生格式的数据。
                        //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
                        //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
                        new Blob([wbout], { type: 'application/octet-stream' }),
                        //设置导出文件名称
                        '学员列表.xlsx'
                    )
                } catch (e) {
                    if (typeof console !== 'undefined') console.log(e, wbout)
                }
                return wbout
            })

        },
        exportExcel2() {//导出用户提问表格
            /* 从表生成工作簿对象 */
            this.lists2().then(res => {
                let wb = XLSX.utils.table_to_book(document.querySelector('#out-table2'))
                /* 获取二进制字符串作为输出 */
                var wbout = XLSX.write(wb, {
                    bookType: 'xlsx',
                    bookSST: true,
                    type: 'array'
                })
                try {
                    FileSaver.saveAs(
                        //Blob 对象表示一个不可变、原始数据的类文件对象。
                        //Blob 表示的不一定是JavaScript原生格式的数据。
                        //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
                        //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
                        new Blob([wbout], { type: 'application/octet-stream' }),
                        //设置导出文件名称
                        '用户问题.xlsx'
                    )
                } catch (e) {
                    if (typeof console !== 'undefined') console.log(e, wbout)
                }
                return wbout
            })

        },
        deleteContent(id, courseId) {//删除内容
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const params = {
                    brandId: this.brandId,
                    contentId: id
                }
                contentDelet(params).then(res => {
                    if (res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getcontentList(courseId)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        changeCourse(checked, index, id) {//点击讲师课程切换列表显示隐藏
            let tableDataTeacher = this.tableDataTeacher
            let numArr = tableDataTeacher.filter(item => {
                return item.record.checked == true
            })
            if (checked) {
                tableDataTeacher.forEach(item => {
                    item.record.checked = false
                })
            } else if (!checked && numArr.length > 0) {
                tableDataTeacher.forEach(item => {
                    item.record.checked = false
                })
                tableDataTeacher[index].record.checked = true
            } else if (!checked && numArr.length == false) {
                this.courseContentList = []
                tableDataTeacher[index].record.checked = true
            }
            console.log(checked, numArr.length < 0)
            this.tableDataTeacher = tableDataTeacher
            this.getcontentList(id)
        },
        async getcontentList(courseId) {//获取内容列表
            const params = {
                brandId: this.brandId,
                courseId: courseId,
            }
            const { data } = await contentList(params)
            if (data.code == 200) {
                this.courseContentList = data.data
            }
        },
        titleChange(ind) {
            this.ind = ind
            if (ind == 0) {
                this.list()
                // this.lists()
            } else if (ind == 1) {
                this.list2()
            } else if (ind == 2) {
                this.list3()
            }
        },
        async levelStudyList() {//等级学习时长数据
            this.statusInd = 0
            const params = {
                traineeUserId: this.userIds
            }
            const { data } = await getLevelList(params)
            this.tableDataStatus = data.data
        },
        async courseStudyList() {//各科学习时长数据
            this.statusInd = 1
            const params = {
                traineeUserId: this.userIds,
                brandId: this.brandId
            }
            const { data } = await getcourselList(params)
            this.tableDataStatus = data.data
        },

        search() {//搜索
            let ind = this.ind
            if (ind == 0) {
                this.list()
                // this.lists()
            } else if (ind == 1) {
                this.list2()
            } else {
                this.list3()
            }
        },
        reset() {//重置
            this.vipStr = ''
            this.agentUserId = '',
                this.brandId = '',
                this.brandIdStr = ''
            this.pageIndex = 1,
                this.pageSize = 10,
                this.searchStr = '',
                this.vip = '',
                this.dateList = []
            this.agentUserIdStr = ''
            let ind = this.ind
            if (ind == 0) {
                this.list()
                // this.lists()
            } else if (ind == 1) {
                this.list2()
            } else {
                this.list3()
            }
        },
        addUser() {//新增按钮
            this.userIds = ''
            this.isAdd = true
            if (this.ind == 0) {
                this.dialogVisible = true
                this.name = '',
                    this.phone = '',
                    this.brandId = '',
                    this.agentUserId = '',
                    this.vip = 2,
                    this.sex = 0,
                    this.birthday = '',
                    this.code = [],
                    this.storeName = '',
                    this.traineeIds = ''
            } else if (this.ind == 1) {
                this.dialogVisible2 = true
                this.name = '',
                    this.phone = '',
                    this.brandId = '',
                    this.agentUserId = '',
                    this.vip = 0,
                    this.sex = 0,
                    this.birthday = '',
                    this.code = [],
                    this.storeName = ''
                this.picUri = ''
                this.des = ''
                this.teacherId = ''
                this.teacherIds = ''
            } else {
                this.dialogVisible3 = true
                this.name = '',
                    this.phone = '',
                    this.brandId = '',
                    this.provinceId = ''
                this.wxacodeUri = ''
                this.agentIds = ''
                this.userId = ''
            }
        },
        async confirmAddTrainee() {//添加学员
            if (this.traineeIds) {//更新学员
                let params
                if (this.vip == this.defaultVip) {//说明没有改变vip
                    params = {
                        name: this.name,
                        phone: this.phone,
                        brandId: this.brandId,
                        agentUserId: this.agentUserId,
                        // vip: this.vip,
                        sex: this.sex,
                        birthday: this.birthday,
                        province: this.code[0],
                        city: this.code[1],
                        area: this.code[2],
                        storeName: this.storeName,
                        traineeId: this.traineeIds,
                        userId: this.userIds
                    }
                } else {
                    params = {
                        name: this.name,
                        phone: this.phone,
                        brandId: this.brandId,
                        agentUserId: this.agentUserId,
                        vip: this.vip,
                        sex: this.sex,
                        birthday: this.birthday,
                        province: this.code[0],
                        city: this.code[1],
                        area: this.code[2],
                        storeName: this.storeName,
                        traineeId: this.traineeIds,
                        userId: this.userIds
                    }
                }
                const { data } = await traineeUpdate(params)
                if (data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '更新成功!'
                    });
                    this.dialogVisible = false
                    this.list()
                    this.lists()
                    this.refreshToken()
                } else {
                    this.$message({
                        type: 'error',
                        message: data.msg
                    });
                }
            } else {
                const params = {
                    name: this.name,
                    phone: this.phone,
                    brandId: this.brandId,
                    agentUserId: this.agentUserId,
                    vip: this.vip,
                    sex: this.sex,
                    birthday: this.birthday,
                    sex: this.sex,
                    province: this.code[0],
                    city: this.code[1],
                    area: this.code[2],
                    storeName: this.storeName
                }
                console.log(params)
                const { data } = await traineeAdd(params)
                if (data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '添加成功!'
                    });
                    this.dialogVisible = false
                    this.list()
                    this.lists()
                } else {
                    this.$message({
                        type: 'error',
                        message: data.msg
                    });
                }
            }

        },
        async list() {//学员列表
            const params = {
                agentUserId: this.agentUserIdStr,
                brandId: this.brandIdStr,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                searchStr: this.searchStr ? this.searchStr.trim() : '',
                vip: this.vipStr,
                vipTimeMin: this.dateList[0],
                vipTimeMax: this.dateList[1]
            }
            const { data } = await traineeList(params)
            if (data.code == 200) {
                this.tableData = data.data.records
                this.total = data.data.total
            }
        },

        async lists() {//学员列表导表格专用
            const params = {
                agentUserId: this.agentUserIdStr,
                brandId: this.brandIdStr,
                pageIndex: 1,
                pageSize: 999,
                searchStr: this.searchStr ? this.searchStr.trim() : '',
                vip: this.vipStr,
                vipTimeMin: this.dateList[0],
                vipTimeMax: this.dateList[1]
            }
            const { data } = await traineeList(params)
            if (data.code == 200) {
                this.tableDatas = data.data.records
                // this.total=data.data.total
            }
        },
        async lists2() {//用户ai问题
            const { data } = await getAiList({pageIndex:1,pageSize:99999,})
            if (data.code == 200) {
                this.tableDatas2 = data.data.records.filter(item=>{return item.type==1})
                console.log(this.tableDatas2 )
                // this.total=data.data.total
            }
        },
        detailsTrainee(row) {//学员详情
            this.isAdd = false
            this.dialogVisible = true
            this.traineeIds = row.traineeId
            this.userIds = row.userId
            this.brandId = row.brandId
            this.levelStudyList()
            this.list4()
            traineeLook({ userId: row.userId }).then(res => {
                this.code = []
                this.storeName = ''
                this.birthday = ''
                console.log(res.data.data)
                let traineeInfo = res.data.data.traineeInfo
                let userInfo = res.data.data.userInfo
                this.name = userInfo.name
                this.phone = userInfo.phone

                this.agentUserId = traineeInfo.agentUserId
                this.defaultVip = this.vip = traineeInfo.vip
                this.sex = userInfo.sex
                this.birthday = userInfo.birthday
                this.studyTimeMinutes = traineeInfo.studyTimeMinutes
                this.levelId = traineeInfo.levelId
                this.code = [(userInfo.province).toString(), (userInfo.city).toString(), (userInfo.area).toString()]
                this.storeName = traineeInfo.storeName


            })
        },
        async confirmAddTeacher() {//添加老师
            if (this.teacherIds) {//更新
                const params = {
                    birthday: this.birthday,
                    des: this.des,
                    name: this.name,
                    phone: this.phone,
                    picUri: this.picUri,
                    sex: this.sex,
                    teacherId: this.teacherIds,
                    userId: this.userIds
                }
                const { data } = await teacherUpdate(params)
                if (data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '更新成功!'
                    });
                    this.dialogVisible2 = false
                    this.list2()
                } else {
                    this.$message({
                        type: 'error',
                        message: data.msg
                    })
                }
            } else {//编辑
                const params = {
                    birthday: this.birthday,
                    des: this.des,
                    name: this.name,
                    phone: this.phone,
                    picUri: this.picUri,
                    sex: this.sex
                }
                const { data } = await teacherAdd(params)
                if (data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '添加成功!'
                    });
                    this.dialogVisible2 = false
                    this.list2()
                } else {
                    this.$message({
                        type: 'error',
                        message: data.msg
                    })
                }
            }

        },
        async list2() {//讲师列表
            const { data } = await teacherList()
            if (data.code == 200) {
                this.tableData2 = data.data
            }
        },
        detailsTeacher(row) {//讲师详情
            this.isAdd = false
            this.dialogVisible2 = true
            this.name = row.name
            this.phone = row.phone
            this.des = row.des
            this.sex = row.sex
            this.birthday = row.birthday
            this.picUri = row.picUri
            this.teacherIds = row.teacherId
            this.userIds = row.userId
            this.courseBrandId=1
            this.getcourseList()
        },
        async getcourseList() {//获取教师课程包
            const params = {
                brandId: this.courseBrandId,
                teacherUserId: this.userIds
            }
            const { data } = await courseList(params)
            if (data.code == 200) {
                data.data.forEach(item => {
                    item.record.checked = false
                })
                this.tableDataTeacher = data.data
            }
        },
        detailsAgetn(row) {//代理商详情
            this.isAdd = false
            let month = new Date().getMonth() + 1
            this.month = month.toString()
            this.vipData = {}
            this.userData = {}
            agentDes({ userId: row.userId }).then(res => {
                this.dialogVisible3 = true
                this.name = res.data.data.name
                this.phone = res.data.data.phone
                this.brandId = res.data.data.brandId
                this.provinceId = res.data.data.province ? String(res.data.data.province) : ''
                this.userIds = res.data.data.userId
                this.agentIds = res.data.data.agentId,
                    this.wxacodeUri = 'https://sihuankangyuan.cn/' + res.data.data.wxacodeUri
            })

            console.log(row.agentId)
        },
        async confirmAddAgent() {//添加代理商
            if (this.userIds) {
                const params = {
                    name: this.name,
                    phone: this.phone,
                    brandId: this.brandId,
                    province: this.provinceId,
                    userId: this.userIds,
                    agentId: this.agentIds,
                    config: `storeName=${this.userstoreName}`
                }
                const { data } = await agentUpdate(params)
                if (data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '更新成功!'
                    });
                    this.dialogVisible3 = false
                    this.list3()
                } else {
                    this.$message({
                        type: 'error',
                        message: data.msg
                    })
                }
            } else {
                const params = {
                    name: this.name,
                    phone: this.phone,
                    brandId: this.brandId,
                    province: this.provinceId,
                    config: `storeName=${this.userstoreName}`
                }
                const { data } = await agentAdd(params)
                if (data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '添加成功!'
                    });
                    this.dialogVisible3 = false
                    this.list3()
                } else {
                    this.$message({
                        type: 'error',
                        message: data.msg
                    })
                }
            }

        },
        async list3() {//代理商列表
            const { data } = await agentList({ brandId: this.brandIdStr, searchName: this.searchStr ? this.searchStr.trim() : '' })
            if (data.code == 200) {
                this.tableData3 = data.data
            }
        },
        async list4() {//代理商列表（新增学员，选择学院品牌改变代理商）
            this.agentUserId = ''
            const { data } = await agentList({ brandId: this.brandId })
            if (data.code == 200) {
                this.tableData3 = data.data
            }
        },
        upload(selectFileobj) {
            let that = this;
            cos.options.getAuthorization("", function (obj) {
                let name = selectFileobj.name.replace(/MP4/g, "mp4");

                let filename = 'content/resource/newManage/video/' +
                    name.slice(0, name.length - 4) +
                    new Date().getTime() +
                    name.slice(-4);
                console.log(filename)
                cos.sliceUploadFile(
                    {
                        Bucket: sessionStorage.getItem("bucket") /* 必须 */,
                        Region: "ap-nanjing" /* 存储桶所在地域，必须字段 */,
                        Key: filename /* 必须 */,
                        Body: selectFileobj /* 必须 */,
                        onTaskReady: function (taskId) {
                            /* 非必须 */
                            console.log(taskId);
                            that.taskId = taskId
                        },
                        onHashProgress: function (progressData) {
                            /* 非必须 */
                            console.log(JSON.stringify(progressData));
                        },
                        onProgress: function (progressData) {
                            /* 非必须 */
                            console.log(JSON.stringify(progressData));
                            that.percentage = parseInt(progressData.percent * 100);
                        },
                    },
                    function (err, data) {
                        console.log(err || data);
                        if (data) {
                            let Location = data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g, 'https://sihuankangyuan.cn')
                            // that.vidersrc = "https://" + data.Location;
                            that.vidersrc = Location

                            sessionStorage.setItem('vidersrc', Location)

                            setTimeout(() => {
                                //   var videosrc = document.getElementById('videosrc');
                                // that.duration=parseInt(videosrc.duration)
                                // console.log(parseInt(videosrc.duration))
                                that.$message({
                                    message: `上传成功，视频正在转码请稍后再播放`,
                                    type: 'warning',
                                });
                            }, 1000)
                        }
                    }
                );
            });
        },
        quillchange(delta, oldDelta, source) {
            console.log(delta, oldDelta, source)
        },
        uploadimg2(e) {//上传图片
            if (this.$refs['fileimg'].files[0]) {
                // let filename = this.$refs['fileimg'].files[0].name
                let selectFileobj = this.$refs['fileimg'].files[0]
                this.upload2(selectFileobj)
            }
        },
        upload2(selectFileobj) {
            let that = this
            let name = selectFileobj.name
            let filename = 'content/resource/newManage/image/' +
                name.slice(0, name.length - 4) +
                new Date().getTime() +
                name.slice(-4);
            console.log(filename)
            cos.options.getAuthorization('', function (obj) {
                cos.putObject({
                    Bucket: sessionStorage.getItem('bucket'), /* 必须 */
                    Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
                    Key: filename,              /* 必须 */
                    StorageClass: 'STANDARD',
                    Body: selectFileobj, // 上传文件对象
                    onProgress: function (progressData) {
                        // console.log(JSON.stringify(progressData));
                        console.log(progressData)
                    }
                }, function (err, data) {
                    console.log(err || data);
                    if (data) {
                        // let url='https://'+data.Location
                        let picUri = data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g, 'https://sihuankangyuan.cn')
                        that.picUri = picUri
                    }
                });
            })
        },
        async getList() {
            const params = {
                brandId: this.brandId,
                parentId: this.id
            }
            const { data } = await currentCourseList(params)
            if (data.code == 200) {
                this.tableData = data.data
                console.log(data.data)
            }
        },
        dele(row) {
            console.log(row)
            const params = {
                courseId: row.id,
                brandId: this.brandId
            }
            this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                courseDeleteAll(params).then(res => {
                    if (res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getList()
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.data.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        goback() {
            this.$router.go(-1)
        },
        async leave() {
            //获取等级列表
            const { data } = await levelList({ roleId: 3 });
            if (data.code == 200) {
                this.levelLists = data.data;
            } else {
                this.$message.error(data.msg);
            }
        },
        lookvideo(row) {
            this.$router.push({ path: '/courseAdd', query: row })
        },
        lookcontent2(row) {
            this.$router.push({ path: '/courseContent', query: row })
        },
        addtext(row) {
            this.$router.push({ path: '/courseContent', query: { id: row.id, brandId: this.brandId } })
        },
        addtext2(row) {
            this.$router.push({ path: '/coursePageList3', query: { id: row.id, brandId: this.brandId } })
        },
        addtext4(row) {
            this.$router.push({ path: '/exercises', query: { id: row.id, brandId: this.brandId } })
        },
        lookcontent(row) {
            this.$router.push({ path: '/contentAdd', query: row })
        },
        // async list() {//课程包列表
        // const params = {
        // brandId: this.brandId,
        // levelId: this.leveId,
        // pageIndex: this.pageIndex,
        // pageSize: this.pageSize,
        // // contentType:this.radio,
        // searchStr: this.input
        // }
        // const { data } = await courseInfoList(params)
        // if (data.code == 200) {
        // this.tableData = data.data.records,
        // this.total = Number(data.data.total)
        // }
        // console.log(data)
        // },
        details(row) {
            this.$router.push({ path: '/courseChildAdd3', query: { row: JSON.stringify(row), brandId: this.brandId } })
        },
        details2(row) {
            this.$router.push({ path: '/courseParentAdd3', query: { row: JSON.stringify(row), brandId: this.brandId } })
        },
        lookcontent3(row) {
            this.$router.push({ path: '/courseChildAdd2', query: { id: row.id, brandId: this.brandId } })
        },
        lookcontent4(row) {
            this.$router.push({ path: '/coursePageList2', query: { id: row.id, brandId: this.brandId } })
        },
        addcontent() {
            this.$router.push({ path: '/courseParentAdd', query: { id: this.id, brandId: this.brandId } })
            //   if(this.radio=='3'){
            //     this.$router.push({path:'/contentAdd'})
            //   }else{
            //     this.$router.push({path:'/videoAdd'})
            //   }
        },
        addcontent2() {
            this.$router.push({ path: '/courseChildAdd', query: { id: this.id, brandId: this.brandId } })
            //   if(this.radio=='3'){
            //     this.$router.push({path:'/contentAdd'})
            //   }else{
            //     this.$router.push({path:'/videoAdd'})
            //   }
        },

        handleSelectionChange(val) {//选中
            console.log(val)
            if (val.length > 0) {
                this.isdisabled = false
            } else {
                this.isdisabled = true
            }
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageSize = val
            this.list()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pageIndex = val
            this.list()
        },

        deleall() {
            this.$confirm('此操作将永久删除选中项, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        }
    },
};
</script>

<style lang='less' scoped>
/deep/ .dialog1 .el-dialog__body {
    height: 85%;
    overflow: auto;
}

/deep/ .dialog1 .el-dialog {
    margin: 0 !important;
    float: right;
    height: 100%;
}

.titleLayout {
    display: flex;
    border-radius: 8px 8px 0 0;
    background: #C6E7E2;
    height: 34px;
    line-height: 34px;
}

.currentTitle {
    background: #fff;
    border-radius: 8px 8px 0 0;
    height: 34px;
    line-height: 34px;
}

.main {
    // min-height: 800px;
    // background: #fff;
    margin: 19px 0;
    padding: 23px;
    border-radius: 14px;
}

// .main>div:first-child {
//   font-size: 26px;
//   // font-weight: bold;
// }

// .main>div:nth-child(2) {
//   display: flex;
//   justify-content: space-between;
//   height: 34px;
//   margin: 18px 0;

//   div {
//     display: flex;

//     img {
//       width: 25px;
//       height: 25px;
//     }
//   }
// }
.changeSel {
    padding: 8px 22px;
    background: #2FAFA1;
    border-radius: 50px;
    color: #fff;
}

/deep/.el-radio-button__inner {
    height: 34px;
    line-height: 10px;
}

.cinput /deep/.el-input__inner {
    height: 34px;
    line-height: 10px;
}

/deep/.el-button--primary {
    height: 34px;
    line-height: 10px;
}

/deep/.el-table::before {
    height: 0;
}

.ac /deep/.el-input__inner {
    border: none;
    background: #f7f7f7;
    // margin-right: 11px;
}

.defaultcolor {
    color: #3C4645;
}

.red {
    color: #ff1b0b;
}

/deep/.el-radio__input.is-checked+.el-radio__label {
    color: #00A78E;
}

/deep/.el-radio__input.is-checked .el-radio__inner {
    border-color: #00A78E;
    background: #00A78E
}

.selectContent {
    color: #00A78E;
}

.selTitle {
    color: #3C4645;
    font-weight: bold
}

.noselTitle {
    color: #ACB6B5;
    font-weight: 300
}

.bf {
    background: #fff
}

.b00 {
    background: #00A78E
}

.cstatus1 {
    color: #FAAD14;
}

.cstatus2 {
    color: #54C97F;
}

.cstatus3 {
    color: #ACB6B5;
}

/deep/.usertable .el-dialog__body {
    height: 75vh;
    overflow: auto;
}

/deep/.el-radio {
    margin: 10px 0;
}</style>
